<template>
  <header>
    <div class="profile">
      <div class="your-profile color-href">{{ $root.getCurrentUser() }}</div>
      <a href="" class="exit">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
          <path
              d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>
      </a>
    </div>
    <div class="main_menu">
      <div class="spred_lvl1">
        <div class="spred_lvl1_spred">
          Панель (<span ref="timer">10 с.</span>)
        </div>
        <div class="spred_lvl1_main-spred">
          <router-link to="/" class="color-href">Панель</router-link>
          /
          <router-link to="spread" class="color-href">Спред</router-link>
        </div>
      </div>
      <div class="spred_lvl2">
        <div class="block_download">
          <details id="block_download">
            <summary id="summary">{{ statusSort.length === Object.keys(statusList).length ? "Все" : statusSort.map(e => statusList[e]).join(", ") }}</summary>
            <ul>
              <li v-for="(statusName, statusId) in statusList" :key="statusId">
                <button :style="this.statusSort.includes(statusId) ? 'background-color: #28a53d' : ''" class="button_list" @click="toggleStatusSort(statusId)">
                  <i class="fa-solid fa-plus fa-sm"></i>{{ statusName }}
                </button>
              </li>
            </ul>
          </details>
          <div class="block-button">
            <button class="button button3" @click="enableAllStatuses()" type="button">Все</button>
          </div>
        </div>
        <div class="block-button2">
          <div class="button-group-lvl3">
            <button
                :class="{'button-group-lvl3_cripto': true, 'button-group-active': transactionFilter === 'replenishment'}"
                @click="setTransactionFilter('replenishment')">
              Пополнения
            </button>
            <button
                :class="{'button-group-lvl3_cripto': true, 'button-group-active': transactionFilter === 'write_off'}"
                @click="setTransactionFilter('write_off')">
              Списания
            </button>
            <button
                :class="{'button-group-lvl3_cripto_end': true, 'button-group-active': transactionFilter === 'unbound'}"
                @click="setTransactionFilter('unbound')">
              Непривязанные
            </button>
          </div>
        </div>
      </div>
    </div>
  </header>
  <div class="block-table">
    <div class="table1">
      <table class="content-table">
        <tr>
          <th class="id">WP ID</th>
          <th class="type-site">Сайт</th>
          <th class="type-site">Тип</th>
          <th class="status">Статус</th>
          <th class="direction">Направление</th>
          <th class="coming">Приход</th>
          <th class="time">Время</th>
          <th class="client">Клиент</th>
          <th class="update-create-wallet">Созд.</th>
          <th class="update-create-wallet">Обновл.</th>
          <th class="update-create-wallet">Wallet</th>
        </tr>
        <tr v-bind:key="trade.id" v-for="trade in tradeList" :class="STATUS_COLOR[trade.status]">
          <td class="id"><span id="id-user" v-text="trade.id"></span></td>
          <td>gogobit</td>
          <td>Продажа</td>
          <td class="status"><a @click="changeStatusOpen(trade)" class="color-href" href="#">{{ statusList[trade.status] }}</a></td>
          <td><span id="btc" class="font_weight">{{ trade.give_amount }}</span> {{ trade.give_currency_code }}<a
              href=""><img class="pencil" src="@/assets/checkarrow.png"
                           alt=""> </a><span id="rub" class="font_weight">{{ trade.get_amount }}</span>
            {{ trade.get_currency_code }}<br>
          </td>

          <td v-if="!trade.has_bot_transaction" class="coming">
            <a href="#" @click="createTransactionRelation(trade.id)"><img class="image" src="@/assets/recycle.png" alt="Повтор"></a>
          </td>
          <td v-else class="coming">
            <a href="#" @click="createTransactionRelation(trade.id)"><img class="image" src="@/assets/check.png" alt="Повтор"></a>
            <span>{{ trade.bot_transaction.total_price }}</span>
          </td>
          <td class="time" v-if="trade.has_bot_transaction" v-html="formatTime(trade.bot_transaction.created_at)"></td>
          <td v-else></td>

          <td><a href="#" @click="openUserInfo(trade)" class="color-href" id="full_name">{{ trade.full_name }}</a><br><a
              href="#" @click="openUserInfo(trade)"
              class="color-href number-email" id="email">{{ trade.user_email }}</a>
          </td>
          <td class="update-create" v-html="formatTime(trade.created_at)"></td>


          <td class="update-create" v-html="formatTime(trade.updated_at)"></td>
          <td class="wallet"><a class="update-create-wallet color-href tooltip" v-bind:data-tooltip="trade.out_wallet"
                                @click="copyToClipboard(trade.out_wallet)">Wallet </a><br>
          </td>
        </tr>
      </table>
      <div class="scrolling-wrapper_in_category">
            <a href="#"
               class="scrolling_wrapper_number"
               v-if="this.currentPage > 1"
              @click="changeTradePageTo(this.currentPage - 1)">
              ←
            </a>
            <a class="scrolling_wrapper_number number_priority">{{ this.currentPage }}</a>
            <a href="#"
               class="scrolling_wrapper_number"
               v-if="this.currentPage + 1 <= this.tradePages"
               @click="changeTradePageTo(this.currentPage + 1)">
              →
            </a>
        </div>
    </div>
    <div class="table2">
      <table class="content-table">
        <tr>
          <th class="add">Доб. в Сеть</th>
          <th class="add">Доб. в Бинанс</th>
          <th class="check">Счет</th>
          <th class="summ">Сумма</th>
          <th class="bot">Подт.</th>
          <th class="bot">Бот.</th>
        </tr>
        <tr v-for="transaction in transactionList" :key="transaction.id" :class="transaction.coin === 'USDT' ? 'background-red' : 'background-green'">
          <td class="add" v-html="formatTime(transaction.created_at)"></td>
          <td class="add" v-html="formatTime(transaction.created_at)"></td>
          <td>{{transaction.bot_name}}</td>
          <td>{{ transaction.amount }} {{ transaction.coin }} ({{ transaction.network }})</td>
          <td class="bot">
            <button class="tranthaction">{{transaction.confirm_times}}</button>
          </td>
          <td class="bot">
            <img v-if="!transaction.is_error && transaction.is_sold" class="image2" src="@/assets/check2.png" alt="Успех">
            <img v-else-if="!transaction.is_sold && !transaction.is_error" class="image2" src="@/assets/timer.png" alt="Ожидание">
            <img v-else class="image2" src="@/assets/error.png" alt="Ошибка">
          </td>
        </tr>
      </table>
      <div class="scrolling-wrapper_in_category">
            <a href="#"
               class="scrolling_wrapper_number"
               v-if="this.currentPageTransactions > 1"
              @click="changeTransactionPageTo(this.currentPageTransactions - 1)">
              ←
            </a>
            <a class="scrolling_wrapper_number number_priority">{{ this.currentPageTransactions }}</a>
            <a href="#"
               class="scrolling_wrapper_number"
               v-if="this.currentPageTransactions + 1 <= this.transactionPages"
               @click="changeTransactionPageTo(this.currentPageTransactions + 1)">
              →
            </a>
        </div>
    </div>
  </div>


  <div class="modal" id="change_cripto" v-if="botTransactionModal">
    <div class="back"></div>
    <div class="modal_panel2" v-click-outside="closeTransactionModal">
      <div class="data_modal1_2">
        <div class="fu-fu">Изменение крипто операции</div>
        <i class="fa-solid fa-xmark" @click="botTransactionModal = false;"></i>
      </div>
      <div class="data_modal2_2">
        <div class="data_modal1_2_text">Крипто операции</div>
        <div class="select_option">
          <select v-model="selectedBind" class="select_opt">
            <option value="-1">Нет</option>
            <option :value="bind.id" v-for="bind in tradeBinds" :key="bind.id">{{ bind.name }}</option>
          </select>
        </div>
      </div>
      <div class="modal_panel_lvl3">
        <div class="modal_panel_lvl3_block">
          <button class="modal_panel_lvl3_cancel" @click="botTransactionModal = false;">Отмена</button>
          <button class="modal_panel_lvl3_save" @click="applyTransaction">Сохранить</button>
        </div>
      </div>
    </div>
  </div>

    <div class="modal" id="change_cripto" v-if="changeStatusModal">
    <div class="back"></div>
    <div class="modal_panel2" v-click-outside="closeStatusModal">
      <div class="data_modal1_2">
        <div class="fu-fu">Изменение статуса заявки</div>
        <i class="fa-solid fa-xmark" @click="changeStatusModal = false;"></i>
      </div>
      <div class="data_modal2_2">
        <div class="data_modal1_2_text">Статус заявки</div>
        <div class="select_option">
          <select v-model="selectedStatus" class="select_opt">
            <option :value="statusId" v-for="(statusName, statusId) in statusList" :key="statusId">{{ statusName }}</option>
          </select>
        </div>
      </div>
      <div class="modal_panel_lvl3">
        <div class="modal_panel_lvl3_block">
          <button class="modal_panel_lvl3_cancel" @click="changeStatusModal = false;">Отмена</button>
          <button class="modal_panel_lvl3_save" @click="saveStatus">Сохранить</button>
        </div>
      </div>
    </div>
  </div>



  <div class="modal" id="modal" v-if="userInfoModal">
    <div class="back"></div>
    <div v-click-outside="closeUserInfo" class="modal_panel">
      <div class="data_modal1">
        <div class="fu-fu">Данные</div>
        <i class="fa-solid fa-xmark" @click="userInfoModal = false;"></i>
      </div>
      <div class="data_modal2">
        <div class="block-email-num-ip">
          <div class="id-sum">
            <div class="indent">E-mail:</div>
            <br>
            <div>IP:</div>
            <br>
          </div>
          <div class="id-sum-options">
            <div class="indent color-href">{{ currentTradeData.user_email }}</div>
            <br>
            <div>{{ currentTradeData.user_ip }}</div>
            <br>
          </div>
        </div>
        <div class="block-id-sum">
          <div class="id-sum">
            <div class="indent">ID заявки:</div>
            <br>
            <div>Сумма:</div>
            <br>
            <div>Имя:</div>
            <br>
            <div>На счёт:</div>
          </div>
          <div class="id-sum-options">
            <div class="indent">{{ currentTradeData.id }}</div>
            <br>
            <div>{{ currentTradeData.get_amount }} {{ currentTradeData.get_currency_code }} ({{ currentTradeData.payment_system_get }})</div>
            <br>
            <div>{{ currentTradeData.full_name }}</div>
            <br>
            <div>{{ currentTradeData.user_account }}</div>
            <br>
            <button
                @click="copyToClipboard(`${currentTradeData.full_name}\n${currentTradeData.user_account}`)"
                class="tranthaction color-href">Скопировать ID,валюту,сумму,на счет.</button>
          </div>
        </div>
      </div>
      <div class="data_modal3">
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';


export default {
  name: 'HomeView',
  data() {
    return {
      botTransactionModal: false,
      userInfoModal: false,
      selectedBind: -1,
      currentTradeId: -1,
      statusSort: [],
      transactionFilter: 'replenishment',
      currentPage: 1,
      currentPageTransactions: 1,
      currentTradeData: {},
      selectedStatus: 'new',
      changeStatusModal: false,
      intervalStart: 0,

      STATUS_COLOR: {
        verify: 'background-blue',
        new: 'background-white',
        my2: 'background-blue',
        realpay: 'background-green',
        payed: 'background-gray'
      }
    }
  },
  computed: {
    ...mapGetters({
      tradeList: "tradeList",
      tradePages: "tradePages",
      statusList: "statusList",
      transactionList: "transactionList",
      transactionPages: "transactionPages",
      tradeBinds: "currentTradeBinds"
    }),
  },
  methods: {
    ...mapActions({
      getTrades: "loadTrades",
      getTransactions: "loadTransactions",
      fetchTradeBinds: "loadBinds",
      bindTransaction: "bindTransaction",
      changeTradeStatus: "changeStatus",
    }),

    async createTransactionRelation(tradeId) {
      await this.fetchTradeBinds(tradeId);
      this.currentTradeId = tradeId;
      this.botTransactionModal = true;
    },

    async setTransactionFilter(newFilter) {
      this.transactionFilter = newFilter;
      this.currentPageTransactions = 1;
      await this.getTransactions({ page: this.currentPageTransactions, transactionType: this.transactionFilter });
    },

    async changeTransactionPageTo(newPage) {
      this.currentPageTransactions = newPage;
      await this.getTransactions({ page: this.currentPageTransactions, transactionType: this.transactionFilter });
    },

    async changeTradePageTo(newPage) {
      this.currentPage = newPage;
      await this.getTrades({ page: this.currentPage, status: this.statusSort });
    },

    async toggleStatusSort(newStatus) {
      if (this.statusSort.includes(newStatus)) {
        if (this.statusSort.length <= 1) return;
        this.statusSort = this.statusSort.filter(e => e !== newStatus);
      }
      else                                     this.statusSort.push(newStatus);

      this.currentPage = 1;
      await this.getTrades({ page: this.currentPage, status: this.statusSort });
    },

    async enableAllStatuses() {
      this.statusSort = Object.keys(this.statusList).filter(() => true);

      this.currentPage = 1;
      await this.getTrades({ page: this.currentPage, status: this.statusSort });
    },

    openUserInfo(tradeData) {
      this.currentTradeData = tradeData;
      this.userInfoModal = true;
    },

    changeStatusOpen(tradeData) {
      this.currentTradeData = tradeData;
      this.selectedStatus = tradeData.status;
      this.changeStatusModal = true;
    },

    async saveStatus() {
      await this.changeTradeStatus({ tradeId: this.currentTradeData.id, status: this.selectedStatus });
      this.changeStatusModal = false;
    },

    closeUserInfo() {
      this.userInfoModal = false;
    },

    closeTransactionModal() {
      this.botTransactionModal = false;
    },

    closeStatusModal() {
      this.changeStatusModal = false;
    },

    async applyTransaction() {
      await this.bindTransaction({tradeId: this.currentTradeId, transactionId: this.selectedBind});
      this.botTransactionModal = false;
    },

    formatTime(t) {
      let dateTime = new Date(t);
      return `<span class="time">${dateTime.toLocaleTimeString()}</span><br><span class="data">${dateTime.toLocaleDateString()}</span>`;
    },

    async copyToClipboard(textToCopy) {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(textToCopy);
      } else {
        const textArea = document.createElement("textarea");
        textArea.value = textToCopy;

        textArea.style.position = "absolute";
        textArea.style.left = "-999999px";

        document.body.prepend(textArea);
        textArea.select();

        try {
          document.execCommand('copy');
        } catch (error) {
          console.error(error);
        } finally {
          textArea.remove();
        }
      }
      this.$notify("Информация была скопирована в ваш буфер обмена.");
    }
  },
  async mounted() {
    this.$root.setTitle("Панель");
    await this.getTrades({ page: this.currentPage, status: this.statusSort });
    this.statusSort = Object.keys(this.statusList).filter(() => true);
    await this.getTransactions({ page: this.currentPageTransactions, transactionType: this.transactionFilter });
    this.ticks = 0;
    this.autoUpdate = setInterval(() => {
      this.ticks++;
      if (this.ticks >= 10) {
        this.getTransactions({page: this.currentPageTransactions, transactionType: this.transactionFilter});
        this.getTrades({page: this.currentPage, status: this.statusSort});
        this.ticks = 0;
      }
      this.$refs.timer.innerHTML = `${10 - this.ticks} с.`;
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.autoUpdate);
  }
}
</script>
