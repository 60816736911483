import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import SpreadView from '../views/SpreadView.vue';
import LoginView from "@/views/LoginView.vue";
import InitView from "@/views/InitView.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/spread',
    name: 'spread',
    component: SpreadView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/init',
    name: 'init',
    component: InitView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
