<template>
  <notifications />
  <router-view/>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {Notifications} from "@kyvg/vue3-notification";

export default {
  name: 'App',
  components: {Notifications},
  data() {
    return {
      title: 'Gogobit Admin'
    }
  },
  computed: {
    ...mapGetters({userData: 'user', isAuthenticated: 'isAuthenticated'})
  },
  methods: {
    ...mapActions({getUser: 'getUser'}),

    setTitle(newTitle) {
      document.title = `Gogobit Admin | ${newTitle}`;
      this.title = newTitle;
    },

    getCurrentUser() {
      if (!this.userData) return "admin";
      return this.userData.username;
    }
  },
  async mounted() {
    if (this.isAuthenticated)
      await this.getUser();
  }
}
</script>

<style>
  @import './assets/design.css';
</style>
