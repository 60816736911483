import axios from 'axios';

const state = {
    tradeList: [],
    tradePages: 1,
    currentTradeBinds: [],
    statusList: {},
}

const getters = {
    tradeList: state => state.tradeList,
    tradePages: state => state.tradePages,
    statusList: state => state.statusList,
    currentTradeBinds: state => state.currentTradeBinds
}

const actions = {
    async loadTrades({ commit }, { page, status }) {
        if (status.length <= 0) {
            status = null;
        } else {
            status = status.join(",");
        }
        let response = await axios.get('/trades/list', { params: { page, status } });
        if (response.data.success) {
            commit('setTrades', response.data);
            commit('setStatusList', response.data.statuses);
        }
    },

    async loadBinds({ commit }, tradeId) {
        let response = await axios.get('/trades/binds', { params: { trade_id: tradeId } });
        if (response.data.success) {
            commit('setAvailableBinds', response.data.binds);
        }
    },

    async bindTransaction({ commit }, {tradeId, transactionId}) {
        let response = await axios.post(
            '/trades/bind', { trade_id: tradeId, transaction_id: transactionId }
        );
        if (response.data.success) {
            commit('updateTradeData', response.data.trade_data);
        }
    },

    async changeStatus({ commit }, { tradeId, status }) {
        let response = await axios.post(
            '/trades/status', { trade_id: tradeId, status }
        );
        if (response.data.success) {
            commit('updateTradeData', response.data.trade_data);
        }
    }
}

const mutations = {
    setTrades(state, tradesData) {
        state.tradeList = tradesData.data;
        state.tradePages = tradesData.pages_count;
    },
    setStatusList(state, statuses) {
        state.statusList = statuses;
    },
    setAvailableBinds(state, bindsData) {
        state.currentTradeBinds = bindsData;
    },
    updateTradeData(state, tradeData) {
        let neededId = -1;
        state.tradeList.forEach((existingTrade, i) => {
            if (existingTrade.id === tradeData.id) neededId = i;
        })
        if (neededId !== -1) {
            state.tradeList[neededId] = tradeData;
        }
    }
}

export default {
      state,
      getters,
      actions,
      mutations
}