import { createApp } from 'vue';
import Notifications from '@kyvg/vue3-notification'
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';


axios.interceptors.response.use(undefined, function (error) {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch('logOut').then(() => {});
      return router.push('/login')
    }
  }
});
axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://dev.sdark.me/api/v1';  // the FastAPI backend


createApp(App)
    .directive('click-outside', {
        beforeMount(el, binding) {
            el.clickOutsideEvent = function(event) {
                // Check if the clicked element is neither the element
                // to which the directive is applied nor its child
                if (!(el === event.target || el.contains(event.target))) {
                    // Invoke the provided method
                    binding.value(event);
                }
            };
            setTimeout(() => document.addEventListener('click', el.clickOutsideEvent), 100);
        },
        unmounted(el) {
            // Remove the event listener when the bound element is unmounted
            document.removeEventListener('click', el.clickOutsideEvent);
        },
    })
    .component('pass', {
        render() {
            return this.$slots.default(this.$attrs);
        }
    })
    .use(Notifications)
    .use(store)
    .use(router)
    .mount('#app');
