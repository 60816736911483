
<template>
<div class="display_profile">
        <div class="register">
            <span class="register-text">Инициализация админа</span>
            <span class="register-name" style="color:#691818" v-if="error.length > 0">{{ error }}</span>
            <span class="register-name">Логин:</span>
            <input type="text" v-model="username" class="modal_panel_lvl2_input_per register-input" size="40">
            <span class="register-name">Пароль:</span>
            <input type="password" v-model="password" class="modal_panel_lvl2_input_per register-input" size="40">
            <span class="register-name" style="color:#691818" v-if="confirmPassword !== password">Введённые пароли не совпадают</span>
            <span class="register-name">Повторите пароль:</span>
            <input type="password" v-model="confirmPassword" class="modal_panel_lvl2_input_per register-input" size="40">
            <div class="register-save-block">
                <button @click="submit" class="register-save">Войти</button>
            </div>
        </div>
        </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "InitView",
  data() {
    return {
      username: '',
      password: '',
      confirmPassword: '',
      error: '',
    }
  },
  methods: {
    ...mapActions({init: "initAdmin"}),

    async submit() {
      await this.init({ username: this.username, password: this.password });
      if (!this.isAuthenticated) {
        this.error = "Неверные данные пользователя";
      } else {
        await this.$router.push('/');
      }
    }
  },
  computed: {
    ...mapGetters({ isAuthenticated: "isAuthenticated" })
  },
  async mounted() {
    this.$root.setTitle("Инициализация админа");
  }
}
</script>