<template>
  <div v-if="!isLoading">
    <div class="profile">
      <div class="your-profile color-href">{{ $root.getCurrentUser() }}</div>
      <a href="" class="exit">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
          <path
              d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/>
        </svg>
      </a>
    </div>
    <div class="spred_menu">
      <div class="spred_lvl1">
        <div class="spred_lvl1_spred">
          Главная
        </div>
        <div class="spred_lvl1_main-spred">
          <router-link to="/" class="color-href">Главная</router-link>
          /
          <router-link to="spread" class="color-href">Спред</router-link>
        </div>
      </div>
      <div class="spred_lvl2">
        <div class="spred_lvl2_block1">
          <a href="#"
             @click="currentCat = 'sell'"
             class="spred_lvl2_button-group">
            Продажа <i v-if="currentCat === 'sell'" class="fa-solid fa-check"></i>
          </a>
          <a href="#"
             @click="currentCat = 'buy'"
             class="spred_lvl2_button-group2">
            Покупка <i v-if="currentCat === 'buy'" class="fa-solid fa-check"></i>
          </a>
          <div class=" spred_lvl2_well color-href">
                      <span v-for="(directionValue, directionId) in parserIndex" :key="directionId"
                            @click="directionId !== 'index_garantex' ? changeParserIndex(directionId) : null"
                            class="spred_lvl2_well1">
                        {{ INDEX_NAMES[directionId] }} - {{
                          directionValue
                        }} {{ directionId === 'index_CNY' ? 'CNY' : directionId === 'index_KZT_p2p' ? 'KZT' : directionId === 'index_turkey_p2p' ? 'TRY' : 'RUB' }}/USDT
                      </span>
          </div>
        </div>
      </div>
      <div class="spred_lvl3">
        <div class="spred_lvl3_block1">
          <div class="button-group-lvl3">
            <button :class="{'button-group-lvl3_cripto': true, 'button-group-active': catId === 4}" @click="catId = 4">
              Крипто-банки
            </button>
            <button :class="{'button-group-lvl3_cripto': true, 'button-group-active': catId === 3}" @click="catId = 3">
              Крипто-наличка
            </button>
            <button :class="{'button-group-lvl3_cripto_end': true, 'button-group-active': catId === 1}" @click="catId = 1">
              Крипто-крипто
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="table_spred">
      <table>
        <tbody>
        <tr :class="{ 'table_spred_th': true, 'background-green': currentCat === 'sell', 'background-blue': currentCat !== 'sell' }">
          <th class="table_spred_bank background-gray"><span>*</span></th>
          <th class="table_spred_bank" v-for="currency in currentColumns" :key="currency.id">
            <div class="img_bank">
              <a href="#" class="text_color_black" @click="openMinMax(currency.id, currentCat === 'buy')">
                <i class="fa-solid fa-m fa-sm text_color_green" aria-hidden="true"></i>
              </a>
              <i class="fa-solid fa-percent fa-sm pointer" id="modal-percent" @click="openPercentage(currency.id, currentCat === 'buy')" aria-hidden="true"></i>
              <i class="fa-solid fa-power-off fa-sm" @click="currentCat === 'sell' ? disableDirection(null, currency.id) : disableDirection(currency.id, null)"></i>
            </div>
            <span>{{ currency.title }}</span>
          </th>
        </tr>
        <tr v-for="currency in currentRows" :key="currency.id" class="table_spred_td background-gray">
          <td :class="{ 'sidebar': true, 'background-green': currentCat === 'sell', 'background-blue': currentCat !== 'sell' }">
            <div class="img_bank">
              <a href="#" class="text_color_black" @click="openMinMax(currency.id, currentCat === 'sell')">
                <i class="fa-solid fa-m fa-sm text_color_green" aria-hidden="true"></i>
              </a>
              <i class="fa-solid fa-percent fa-sm pointer" id="modal-percent" @click="openPercentage(currency.id, currentCat === 'sell')" aria-hidden="true"></i>
              <i class="fa-solid fa-power-off fa-sm" @click="currentCat === 'sell' ? disableDirection(currency.id, null) : disableDirection(null, currency.id)"></i>
            </div>
            <span class="sidebar_text">{{ currency.title }}</span></td>
          <pass
              v-for="index in currentColumns.length"
              :key="index"
              :direction="currentCat === 'sell' ? directions[currency.id][currentColumns[index - 1].id] : directions[currentColumns[index - 1].id] ? directions[currentColumns[index - 1].id][currency.id] : null"
              v-slot="{ direction }"
          >
            <td :class="{
              'table_spred_block': true,
              'background-dark-gray': direction ? direction.status === '0' : false,
              'background-blue': direction ? direction.status === '2' : false
            }">
             <div class="table_spr">
                <div v-if="direction" class="table_spred_block1">
                    <div class="table_spred_block1_lvl1">
                    <span>{{ parseFloat(currentCat === 'sell' ? direction.course_get : direction.course_give).toFixed(2) }}</span>
                  </div>
                  <div class="table_spred_block1_lvl2">
                      <a target="_blank"
                       :href="`${direction.bestchange_url}#io${direction.bestchange_rating ? direction.bestchange_rating.place - 5 : 0 }`"
                       :style="'text-decoration: none;' + (direction.bestchange_rating ? '' : 'background-color:#9a9d9a;')" class="table_spred_block1_lvl2_coef">
                        {{ direction.bestchange_rating ? direction.bestchange_rating.place : 0 }}
                        </a>
                <a href="#" id="min-max" class="text_color_black" @click="openMinMax(direction)"><i
                    class="fa-solid fa-m fa-lg"></i></a>
                <i
                    :class="{'fa-solid': true, 'fa-power-off': true, 'fa-lg': true, 'text_color_green': direction.status === '1', 'text_color_red': direction.status !== 1}"
                    @click="currentCat === 'sell' ? disableDirection(currency.id, currentColumns[index - 1].id) : disableDirection(currentColumns[index - 1].id, currency.id)"></i>
              </div>

                  </div>
                           <div v-if="direction && direction.bestchange_rating" style="cursor: pointer;" @click="openPercentage(direction)" class="table_spred_block2">
              <span class="text_color_green pointer" id="modal-percent">{{ direction.best_course_diff < 1000 ? direction.best_course_diff : '>1000'}}</span>
              <span class="text_color_red pointer" id="modal-percent">{{ direction.bestchange_disparity < 1000 ? direction.bestchange_disparity : '>1000' }}</span>
            </div>
            </div>
            </td>
          </pass>
        </tr>
        </tbody>
      </table>
      <div class="modal" v-click-outside="closeMinMax" v-if="minMaxModal" id="modal_panel_min-max">
        <div class="back"></div>
        <div v-click-outside="closeMinMax" class="modal_panel_min-max">
          <div class="modal_panel_lvl1">
            <span class="modal_panel_lvl1_summ">Суммы заявки (Min-Max)</span>
            <i @click="closeMinMax()" class="fa-solid fa-xmark"></i>
          </div>
          <div class="modal_panel_lvl2">
            <div class="modal_panel_lvl2_block">
              <span class="min-summ">Min, рубли:</span>
              <input v-model="minAmount" type="number" placeholder="35000" class="modal_panel_lvl2_input" size="40">
            </div>
            <div class="modal_panel_lvl2_block">
              <span class="min-summ">Max, рубли:</span>
              <input v-model="maxAmount" type="number" placeholder="5000000" class="modal_panel_lvl2_input" size="40">
            </div>
          </div>
          <div class="modal_panel_lvl3">
            <div class="modal_panel_lvl3_block">
              <button @click="closeMinMax()" class="modal_panel_lvl3_cancel">Отмена</button>
              <button @click="saveMinMax()" class="modal_panel_lvl3_save">Сохранить</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" v-if="percentageModal" id="modal_panel_change-percent">
        <div class="back"></div>
        <div v-click-outside="closePercentage" class="modal_panel_change-percent">
          <div class="modal_panel_lvl1">
            <span class="modal_panel_lvl1_summ">Изменить процент</span>
            <i class="fa-solid fa-xmark" @click="closePercentage()"></i>
          </div>
          <div class="modal_panel_percent_lvl2">
            <div class="modal_panel_percent_lvl2_block1">
              <span class="demp">Демпинг:</span>
              <input v-model="dumping" type="number" placeholder="-0.5" class="modal_panel_lvl2_input_per" size="40">
            </div>
          </div>


          <div class="modal_panel_lvl3">
            <div class="modal_panel_lvl3_block">
              <button @click="closePercentage()" class="modal_panel_lvl3_cancel">Отмена</button>
              <button @click="savePercentage()" class="modal_panel_lvl3_save">Сохранить</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" v-if="parserIndexModal" id="modal_panel_change-percent">
        <div class="back"></div>
        <div v-click-outside="closeParserIndex" class="modal_panel_change-percent">
          <div class="modal_panel_lvl1">
            <span class="modal_panel_lvl1_summ">Изменить коэффициент парсера</span>
            <i class="fa-solid fa-xmark" @click="closeParserIndex()"></i>
          </div>
          <div class="modal_panel_percent_lvl2">
            <div class="modal_panel_percent_lvl2_block1">
              <span class="demp">Новый коэффициент:</span>
              <input v-model="parserValue" type="number" placeholder="95.6" class="modal_panel_lvl2_input_per" size="40">
            </div>
          </div>


          <div class="modal_panel_lvl3">
            <div class="modal_panel_lvl3_block">
              <button @click="closeParserIndex()" class="modal_panel_lvl3_cancel">Отмена</button>
              <button @click="saveParserIndex()" class="modal_panel_lvl3_save">Сохранить</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div style="display: flex; justify-content: center; align-items: center;" v-else>
    <div class="lds-ripple"><div></div><div></div></div>
  </div>
</template>


<script>
import {mapActions, mapGetters} from "vuex";


export default {
  name: "SpreadView",
  data() {
    return {
      INDEX_NAMES: {
        index_p2p_tinkoff: 'TINKOFF',
        index_p2p_sberbank: 'Сбер',
        index_turkey_p2p: 'Турция P2P',
        index_KZT_p2p: 'Казахстан P2P',
        index_CNY: 'Юань P2P',
        index_garantex: 'GARANTEX'
      },
      currentCat: 'sell',
      catId: 4,
      isLoading: true,
      minMaxModal: false,
      percentageModal: false,
      parserIndexModal: false,
      parserValue: 0,
      dumping: 0,
      minAmount: 0,
      maxAmount: 0,
      parserKey: '',
      currentFilters: {}
    }
  },
  computed: {
    ...mapGetters({
      parserIndex: "parserIndex",
      currencies: "currencies",
      directions: "directions"
    }),

    currentColumns: function() {
      let initialColumns = Object.values(this.currencies).filter(e => this.catId === 4 ? ["0", "2", "4"].includes(e.category_id) : e.category_id === this.catId.toString());
      let initialRows = Object.values(this.currencies).filter(e => e.category_id === "1");
      let idsToRemove = [];
      for (let i = 0; i < initialColumns.length; ++i) {
        let flag = false;
        for (let j = 0; j < initialRows.length; ++j) {
          let dir;
          try {
            dir = this.currentCat === 'sell' ? this.directions[initialRows[j].id][initialColumns[i].id] : this.directions[initialColumns[i].id][initialRows[j].id];
          } catch (e) {
            continue;
          }
          if (dir) {
            flag = true;
          }
        }
        if (!flag) {
          idsToRemove.push(i);
        }
      }
      return initialColumns.filter((v, i) => !idsToRemove.includes(i));
    },

    currentRows: function() {
      // The rows should only be crypto, columns are changing though.
      let initialRows = Object.values(this.currencies).filter(e => e.category_id === "1");

      // Filter out empty rows.
      let idsToRemove = [];
      for (let i = 0; i < initialRows.length; ++i) {
        let flag = false;
        for (let j = 0; j < this.currentColumns.length; ++j) {
          let dir;
          try {
            dir = this.currentCat === 'sell' ? this.directions[initialRows[i].id][this.currentColumns[j].id] : this.directions[this.currentColumns[j].id][initialRows[i].id];
          } catch (e) {
            continue;
          }
          if (dir) {
            flag = true;
          }
        }
        if (!flag) {
          idsToRemove.push(i);
        }
      }

      return initialRows.filter((v, i) => !idsToRemove.includes(i));
    }
  },
  methods: {
    ...mapActions({
      loadParserIndex: "loadParserIndex",
      loadDirections: "loadDirectionsData",
      changeDirections: "changeDirections",
      setParserIndex: "setParserIndex"
    }),

    async savePercentage() {
      let data;
      if (this.currentCat === 'sell') {
        data = {
          ...this.currentFilters,
          course_max_add: this.dumping,
          add_course_get: this.dumping,
        }
      } else {
        data = {
          ...this.currentFilters,
          course_min_add: this.dumping,
          add_course_give: this.dumping,
        }
      }
      await this.changeDirections(data);
      this.percentageModal = false;
    },

    async saveMinMax() {
      let data;
      if (this.currentCat === 'sell') {
        data = {
          ...this.currentFilters,
          min_get: this.minAmount,
          max_get: this.maxAmount,
        }
      } else {
        data = {
          ...this.currentFilters,
          min_give: this.minAmount,
          max_give: this.maxAmount,
        }
      }
      await this.changeDirections(data);
      this.minMaxModal = false;
    },

    openMinMax(direction, isGive = false) {
      if (direction instanceof Object) {
        if (this.currentCat === 'sell') {
          this.minAmount = direction.min_sum2;
          this.maxAmount = direction.max_sum2;
        } else {
          this.minAmount = direction.min_sum1;
          this.maxAmount = direction.max_sum1;
        }
        this.currentFilters = {direction_id: direction.id};
      } else {
        this.currentFilters = isGive ? {currency_id_give: direction} : {currency_id_get: direction};
        this.minAmount = this.maxAmount = 0;
      }
      this.minMaxModal = true;
    },

    openPercentage(direction, isGive = false) {
      if (direction instanceof Object) {
        this.currentFilters = {direction_id: direction.id};
      } else {
        this.currentFilters = isGive ? {currency_id_give: direction} : {currency_id_get: direction};
      }
      this.dumping = 0.0;
      this.percentageModal = true;
    },

    async disableDirection(fromId, toId) {
      if (fromId !== null && toId !== null) {
        console.log(this.directions[fromId][toId].status);
        await this.changeDirections({
          direction_id: this.directions[fromId][toId].id,
          direction_status: this.directions[fromId][toId].status === '0' ? 1 : 0
        });
      } else if (fromId !== null) {
        let allDisabled = true;
        for (let direction of Object.values(this.directions[fromId])) {
          if (direction.status === '1') {
            allDisabled = false;
            break;
          }
        }

        await this.changeDirections({
          currency_id_give: fromId,
          direction_status: allDisabled ? 1 : 0,
        });
      } else {
        let allDisabled = true;
        for (let directionsMap of Object.values(this.directions)) {
          for (let [getId, directionData] of Object.entries(directionsMap)) {
            if (getId === toId) {
              if (directionData.status === '1') allDisabled = false;
            }
          }
        }
        await this.changeDirections({
          currency_id_get: toId,
          direction_status: allDisabled ? 1 : 0,
        });
      }
      await this.loadDirections();
    },

    changeParserIndex(dirId) {
      this.parserValue = this.parserIndex[dirId];
      this.parserKey = dirId.substring(6);
      this.parserIndexModal = true;
    },

    async saveParserIndex() {
      await this.setParserIndex({ key: this.parserKey, value: this.parserValue });
      await this.loadParserIndex();
      this.parserIndexModal = false;
    },

    closeMinMax() {
      this.minMaxModal = false;
    },

    closePercentage() {
      this.percentageModal = false;
    },

    closeParserIndex() {
      this.parserIndexModal = false;
    }
  },

  async mounted() {
    this.$root.setTitle("Спред");
    this.isLoading = true;
    await this.loadParserIndex();
    await this.loadDirections();
    this.isLoading = false;
    this.updateDirections = setInterval(() => {
      this.loadDirections();
    }, 30000);
  },

  beforeUnmount() {
    clearInterval(this.updateDirections);
  }
}
</script>
