import axios from 'axios';


const state = {
    user: null,
}

const getters = {
    user: state => state.user,
    isAuthenticated: state => !!state.user
}

const actions = {
    async initAdmin({ commit }, { username, password }) {
        let response = await axios.post('/auth/init', { username, password });
        if (response.data.success) {
            commit('setUser', response.data.user);
        } else {
            commit('setUser', null);
        }
    },

    async login({ commit }, { username, password }) {
        let response = await axios.post('/auth/login', { username, password });
        if (response.data.success) {
            commit('setUser', response.data.user);
        } else {
            commit('setUser', null);
        }
    },

    async getUser({ commit }) {
        let response = await axios.get('/auth/me');
        if (response && response.data.success) commit('setUser', response.data.user);
    },

    async logOut({ commit }) {
      commit('setUser', null);
    }
}

const mutations = {
    setUser(state, userData) {
        state.user = userData;
    }
}

export default {
      state,
      getters,
      actions,
      mutations
}