import axios from 'axios';


const state = {
    parserIndex: {},
    currencies: {},
    directions: {},
}

const getters = {
    parserIndex: state => state.parserIndex,
    currencies: state => state.currencies,
    directions: state => state.directions,
}

const actions = {
    async loadParserIndex({ commit }) {
        let response = await axios.get('/spread/parser-index');
        if (response.data.success)
            commit('setParserIndex', response.data.indexes);
    },
    // eslint-disable-next-line
    async setParserIndex({ commit }, { key, value }) {
        await axios.post('/spread/parser-index', {key, value});
    },

    async loadDirectionsData({ commit }) {
        let response = await axios.get('/spread/directions');
        if (response.data.success) {
            commit('setDirections', response.data.directions);
            commit('setCurrencies', response.data.currencies);
        }
    },

    async changeDirections({ commit }, data) {
        let response = await axios.post('/spread/directions/change', data);
        if (response.data.success) {
            commit('changeDirections', response.data.data);
        }
    }
}

const mutations = {
    setParserIndex(state, indexData) {
        state.parserIndex = indexData;
    },

    setDirections(state, directionsData) {
        state.directions = directionsData;
    },

    setCurrencies(state, currenciesData) {
        state.currencies = currenciesData;
    },

    changeDirections(state, newDirectionsData) {
        console.log(newDirectionsData);
    }
}

export default {
      state,
      getters,
      actions,
      mutations
}