import { createStore } from 'vuex';
import trades from './modules/trades';
import transactions from './modules/transactions';
import spread from './modules/spread';
import auth from './modules/auth';

export default createStore({
  modules: {
    trades,
    transactions,
    spread,
    auth
  }
})
