import axios from 'axios';


const state = {
    transactionList: [],
    transactionPages: 1,
}

const getters = {
    transactionList: state => state.transactionList,
    transactionPages: state => state.transactionPages,
}

const actions = {
    async loadTransactions({ commit }, { page, transactionType }) {
        let response = await axios.get('/transactions/list', { params: { page, transaction_type: transactionType } });
        if (response.data.success)
            commit('setTransactions', response.data);
    },
}

const mutations = {
    setTransactions(state, transactionsData) {
        state.transactionList = transactionsData.data;
        state.transactionPages = transactionsData.pages_count;
    },
}

export default {
      state,
      getters,
      actions,
      mutations
}